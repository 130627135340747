@import './theme.scss';

@mixin themeify {
  @each $theme-name, $theme-map in $themes {
    $theme-map: $theme-map !global;
    body[data-theme='#{$theme-name}'] & {
      @content;
    }
  }
}

@mixin imageURL($path) {
  background-image: url(map-get($theme-map, 'baseImageURL') + $path);
  background-repeat: no-repeat;
}

@function themed($key) {
  @return map-get($theme-map, $key);
}