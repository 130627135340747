.login_container {
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /*background: url("../../assets/banner_web 1.png")  no-repeat top center;*/
}

.testBgc {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connectBtnBoxText {
  /* Headline / Regular */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  display: flex;
  justify-content: center;
  text-align: center;
  /*  Color / Gray / 500 */
  color: #71717a;
  margin-top: 12px;
  margin-bottom: 32px;
}
.connectBtnBoxTitle {
  /* Title2/Semi Bold */

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  /* identical to box height, or 115% */

  text-align: center;

  /*  Color / Gray / 900 */

  color: #18181b;
}
.walletConnectBtnBox {
  width: 100%;
  margin-top: 34px;
}
.walletConnectBtnBox .walletConnectBtn {
  width: 100%;
  height: 44px;
  border-radius: 8px;
}
.form {
  padding: 24px !important;

  background: #ffffff;
  /*  Color / Gray / 200 */
  border: 1px solid #e4e4e7;
  /* shadow/Medium */

  box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
    0px 6px 15px -3px rgba(0, 0, 0, 0.1);
  border-radius: 18px;
  width: 480px;
}

.btn {
  width: 100%;
  background: #f5f0ff;
  color: #8f6bf4;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  height: 44px;
  border: none;
}
.active {
  background: #663cee;
  color: #ffffff;
}
:global {
  .ant-btn-primary:disabled {
    background: #f5f0ff !important;
    color: #8f6bf4 !important;
    border-color: #f5f0ff !important;
    /* Color/Primary/50 */
  }
}
