@import "../../style/index";

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.headerBox {
  position: relative;
  cursor: pointer;
  @include themeify {
    .openDappBox {
      background: themed(Gray_200);
    }

    .dappBox {
      padding: 8px 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        background: themed(Gray_200);
      }

      .dappImg {
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 6px;
        background: themed(Violet_500);
        color: themed(White_color);
        font-size: themed(Caption2_font-size);
        font-weight: themed(Bold_font-weight);
      }

      .dappName {
        /* Body/Semi Bold */

        font-family: "Inter", sans-serif;
        font-style: normal;
        color: themed(Gray_900);
        font-weight: themed(Bold_font-weight);
        font-size: themed(Body_font-size);
        line-height: 24px;
        margin-left: 8px;
        max-width: 145px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.menuAsideContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 39;
  height: 100%;
  padding: 16px;
  background: #f4f4f5;
  font-family: "Inter", sans-serif;
  font-style: normal;
}

.subMenu {
  margin-top: 16px;
}

.subMenuTitle {
  padding: 8px 0px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
  color: #71717a;
}

.menuItem {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 8px 0;

  &:hover {
    cursor: pointer;
  }

  .icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .title {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #18181b;
  }
}

.isActive {
  background: #663cee;
  border-radius: 4px;

  .icon {
    svg > path {
      fill: #ffffff;
    }
  }

  .title {
    color: #ffffff;
  }
}

:global {
  .ant-popover .ant-popover-content .ant-popover-inner {
    padding: 16px;
    background: #ffffff;
    /*  Color / Gray / 100 */
    box-sizing: border-box;
    border: 1px solid #f4f4f5;
    /* shadow/Medium */
    box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
      0px 6px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
}
