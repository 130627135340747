@import "../../../style/index";

.pushContainer {
  @include themeify {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    header {
      padding: 21px 24px;
      border-bottom: 1px solid #f4f4f5;
      display: flex;
      align-items: center;
      line-height: 30px;
    }
    .text {
      margin-left: 6px;
      /* Headline / Semi Bold */
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */
      /*  Color / Gray / 900 */
      color: #18181b;
    }
    .backIcon {
      cursor: pointer;
    }
    main {
      padding-left: 24px;
    }
    .upload {
      width: 64px;
      height: 64px;

      background: #ffffff;
      /* Color/Primary/200 */
      display: flex;
      border: 1px dashed #c8b1fd;
      border-radius: 54px;
    }
    .uploadBtn {
      color: #663cee;
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .submit {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;

      width: 100%;
      height: 44px;

      /* Color/Primary/50 */
      background: #f5f0ff;
      border-radius: 8px;
    }
    .active {
      background: #663cee;
      color: #ffffff;
    }
    .editor {
      border-width: 1px;
      border-style: solid;
      border-color: #d9d9d9;
      border-radius: 6px;
    }
    .formItem {
      // padding-top: 24px;
    }
    .formText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      display: flex;
      align-items: center;

      /*  Color / Gray / 500 */

      color: #71717a;
    }

    .right {
      padding: 24px 48px;
      display: flex;
      flex-direction: column;
      .tabs {
        height: 36px;
        margin-bottom: 24px;
      }
      .footer {
        margin-top: 24px;
        width: 100%;

        /* Body/Regular */

        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */
        display: flex;
        align-items: center;

        /*  Color / Gray / 500 */

        color: #71717a;
      }
      .privew {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;

        width: 380px;
        min-width: 320px;
        height: 598px;

        /* Color/Base/White */

        background: #ffffff;
        /*  Color / Gray / 200 */

        border: 1px solid #e4e4e7;
        /* shadow/Medium */

        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
          0px 6px 15px -3px rgba(0, 0, 0, 0.1);
        border-radius: 18px;
      }
      .detail {
        font-size: 14px;
        p {
          padding: 0;
          margin: 0;
        }
      }
      .bar {
        width: 100%;
        height: 50px;
        border-top-right-radius: 18px;
        border-top-left-radius: 18px;
        background: #f6f6f6;
        border: 1px solid #e4e4e7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px;
      }
      .back {
        display: flex;
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        /* identical to box height, or 129% */
        color: #037ee5;
        span {
          margin-left: 7px;
        }
      }
      .mid {
        font-family: "SF Pro Text";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #787878;
        display: flex;
        flex-direction: column;
        align-items: center;
        div:first-child {
          font-weight: 500;
          font-size: 17px;
          line-height: 22px;
          text-align: center;
          color: #000000;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        justify-content: center;
        padding: 16px 8px;
        gap: 8px;
        width: 100%;
        height: 500px;
        background: rgba(43, 120, 205, 0.5);
        box-shadow: 0px -0.33px 0px #b6b6ba, 0px 0.33px 0px #b6b6ba;
      }
      .card {
        width: 255px;
        background: #ffffff;
        border: 0.5px solid #b5cadd;
        border-radius: 12px 12px 12px 2px;
        display: flex;
        flex-direction: column;
        padding: 7px 12px;
        font-family: "Inter";
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        /* or 125% */
        word-break: break-all;
        color: #000000;
        .nickname {
          font-weight: 600;
        }
        .title {
          margin-top: 20px;
          font-weight: 600;
        }
      }
      .input {
        width: 100%;
        height: 48px;
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;
        border: 1px solid #e4e4e7;
        background: url("../../../assets/privew-footer.svg");
        background-size: contain;
      }
    }
  }
  :global {
    .ant-upload-select {
      /* Color/Primary/200 */
      color: #663cee;
      border: 1px dashed #c8b1fd !important;
      border-radius: 4px !important;
      width: 115px !important;
      height: 115px !important;
      background: #ffffff;
    }
    .rdw-editor-toolbar {
      border: none;
      border-bottom: 1px solid #f1f1f1;
    }
    .rdw-editor-wrapper {
      padding: 2px !important;
    }
    .rdw-editor-main {
      height: 80px;
      padding: 10px 16px;
    }
    .public-DraftStyleDefault-block {
      margin: 0;
    }
    .rdw-option-wrapper {
      border: none;
    }
    .ant-btn-primary:disabled {
      background: #f5f0ff;
      color: #8f6bf4;
      /* Color/Primary/50 */
    }
    .rdw-link-modal {
      height: auto;
    }
  }
}
