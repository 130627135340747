@import "../../../style/index";
.pushContainer {
  @include themeify {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    font-family: "Inter";
    font-style: normal;
    header {
      padding: 21px 24px;
      border-bottom: 1px solid #f4f4f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      /* identical to box height, or 122% */

      /*  Color / Gray / 900 */

      color: #18181b;
    }
    .history {
      display: flex;
      // justify-content: space-between;
      // align-items: center;
      padding: 24px 0;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      /*  Color / Gray / 900 */

      color: #18181b;
    }
    .subText {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #18181b;
    }
    .btn {
      padding: 0px 16px;
      height: 32px;
      /* Color/Primary/500 */
      background: #663cee;
      border-radius: 6px;
    }
    .btnTxt {
      /* Body/Medium */
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */
      text-align: center;
      /* Color/Base/White */
      color: #ffffff;
      margin-left: 6px;
    }
    .column {
      display: flex;
      flex-direction: column;
    }
    .backIcon {
      cursor: pointer;
    }
    main {
      padding: 0 24px;
    }
    .empty {
      text-align: center;
      height: 80vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  :global {
    .ant-table-thead .ant-table-cell {
      background-color: transparent !important;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400 !important;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /*  Color / Gray / 900 */
      padding: 0 16px 16px 0 !important;
      color: #18181b !important;
    }
    .ant-table-thead .ant-table-cell::before {
      width: 0px !important;
    }
    .ant-table-tbody .ant-table-cell {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /*  Color / Gray / 600 */
      padding: 12px 16px 12px 0px !important;
      color: #52525b;
    }
  }
}
