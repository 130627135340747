@import "../../style/index";

.dappPopoverBox {
  @include themeify {
    background: themed(White_color);
    // width: 320px;
    // min-height: 120px;

    .connectedWalletBox {
      display: flex;
      align-items: center;
      justify-content: left;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: themed(Regular_font-weight);
      font-size: themed(Body_font-size);
      text-align: center;
      color: themed(Gray_500);
      border-bottom: 1px solid themed(Gray_100);
      padding-bottom: 12px;
    }
    .mr6 {
      display: flex;
      margin-right: 6px;
    }
    .dappListBox {
      margin-top: 12px;
      padding: 12px 0;
      border-top: 1px solid themed(Gray_100);
      border-bottom: 1px solid themed(Gray_100);

      .dappListItem {
        display: flex;
        justify-content: left;
        height: 56px;
        padding: 10px 12px;
        position: relative;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: themed(Gray_100);
        }

        .dappInfoBox {
          font-family: "Inter", sans-serif;
          font-style: normal;

          .dappName {
            font-weight: themed(Bold_font-weight);
            font-size: themed(Body_font-size);
            color: themed(Gray_900);
            height: 20px;
            line-height: 20px;
          }

          .dappDesc {
            font-weight: themed(Regular_font-weight);
            font-size: themed(Caption1_font-size);
            line-height: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            color: themed(Gray_500);
          }
        }

        .dappAvatar {
          background: themed(Violet_500);
          width: 36px;
          height: 36px;
          border-radius: 8px;
          line-height: 36px;
          text-align: center;
          color: themed(White_color);
          margin-right: 8px;
        }

        .isCurrentDapp {
          position: absolute;
          right: 22px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .dappManageBox {
      padding: 12px 0;
      // border-bottom: 1px solid themed(Gray_100);
    }
    .logoutBox {
      // margin-top: 12px;
      // margin-bottom: 14px;
    }
    .dappManageItem {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 228px;
      padding: 8px 12px;
      &:hover {
        background: themed(Gray_100);
      }
      //padding: 8px 12px;
      div {
        // margin-right: 8.83px;
      }
    }
    // .dappManageItem {
    //   display: flex;
    //   align-items: center;
    //   width: 100%;
    //   height: 36px;
    //   border-radius: 4px;
    //   cursor: pointer;
    //   padding: 12px 14px;

    //   &:hover {
    //     background: themed(Gray_100);
    //   }

    //   .icon {
    //     margin-right: 10px;
    //   }

    .dappManageTitle {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: themed(Regular_font-weight);
      font-size: themed(Body_font-size);
      line-height: 20px;
      color: themed(Gray_900);
      margin-left: 8px;
    }
    // }
  }
}
