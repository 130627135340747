@import './src/style/index';

.layout {
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.main {
  display: flex;
  width: 100%;
  height: 100%;

  .activeMenu {
    @include themeify {
      color: themed(Violet_500) !important
    }
  }

  .leftOne {
    min-width: 50px;
    max-width: 100px;
    height: 100%;
    @include themeify {
      background: themed(White_color);
      border-right: 1px solid themed(Gray_100);

      .listItem {
        display: block;
        font-size: themed(Body_font-size);

        .listItemButton {
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          padding: 8px;
          //display: block;
          text-align: center;

          .menuTitle {
            margin-top: 8px;
            width: 100%;
            white-space: nowrap;
            display: block;
          }
        }

      }
    ;
      .link {
        text-decoration: none;
        color: themed(Gray_900);
      }
    }
  }

  .main {
    width: 100%;
    height: 100%;
  }
}

.mainContainer {
  width: 100%;
}

.web3mqModal {
  width: 480px;
}

.createDappHeader {
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #18181B;
  position: relative;
  padding: 0 24px;
  .closeIcon {
    cursor: pointer;
  }
}
