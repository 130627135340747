@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("./assets/fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Bold.woff2") format("woff2"),
    url("./assets/fonts/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
body {
  margin: 0 !important;
  font-family: "Inter", "SF Pro SC", "SF Pro Text", "SF Pro Icons",
    "PingFang SC", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root,
body,
html {
  height: 100%;
  overflow: auto;
}
